import { ApolloError, useMutation, useQuery } from '@apollo/client'
import { ExecutionResult } from 'graphql'
import { pick } from 'underscore'
import gql from 'graphql-tag'

import { DataPointType, DateTime, ID, SortOrder } from './types'

import { onError, update } from './utils'

const PATIENT_QUERY = gql`
query patient($patientId:ID!) {
	patient(id: $patientId) {
		id
		mrn
		patientNote
		firstName
		lastName
		email
		dob
		sex
		phone
		invitedOn
		intervals {
			id
			intervalStart
			intervalEnd
			reportNote
			internalNote
			signoff {
				practitioner {
					id
					name
				}
				approvedOn
				cpt_99457
				cpt_99458
				cpt_99458_2
				cpt_99454
			}
			daysOfData
			dataPoints
			alerts {
				id
			}
			dataPointMetrics {
				dataPointType
				hasAlerts
				hasThresholds
        average
			}
		}
		activeInterval {
			id
		}
		watched
		physician
		indication
		hasAlerts
		bodyTraceScaleImei
		bodyTraceBpImei
		smartMeterScaleImei
		smartMeterBpImei
	}
}`


export type PatientInterval = {
	id: ID
	intervalStart: DateTime
	intervalEnd: DateTime
	reportNote?: string
	internalNote?: string
	signoff: Array<{
		practitioner: {
			id: ID
			name: string
		}
		approvedOn?: DateTime
		cpt_99457: boolean
		cpt_99458: boolean
		cpt_99458_2: boolean
		cpt_99454: boolean
	}>
	alerts: Array<{
		id: ID
	}>
	dataPointMetrics: Array<{
		dataPointType: DataPointType
		hasThresholds: boolean
		hasAlerts: boolean
    average?: number
	}>
	daysOfData: number
	dataPoints: Array<DataPointType>
}

export enum Sex {
	NotSpecified = "NotSpecified",
	Male = "Male",
	Female = "Female",
}

export interface IPatientInfo {
	id: ID
	mrn?: string
	patientNote?: string
	firstName: string
	lastName: string
	email?: string
	invitedOn: DateTime
	intervals: Array<PatientInterval>
	activeInterval?: {
		id: ID
	}
	watched: boolean
	dob?: string
	sex?: Sex
	phone?: string
	physician?: string
	indication?: string
	hasAlerts: boolean
	bodyTraceScaleImei?: string
	bodyTraceBpImei?: string
	smartMeterScaleImei?: string
	smartMeterBpImei?: string
}

export const useGetPatientInfo = (patientId: ID): { loading: boolean; error?: ApolloError; patient?: IPatientInfo; } => {
	const gqlQuery = PATIENT_QUERY
	const variables = {
		patientId,
	}
  let skip = !patientId || patientId.toLowerCase() === "new"

	const q = useQuery<{ patient: IPatientInfo }>(gqlQuery, { variables, skip })
	return {
		...q,
		patient: q.data?.patient,
	}
}


const PATIENTS_LIST_QUERY = gql`
query GetPatientListQuery($pageIndex:Int, $rowsPerPage:Int, $sortBy: PatientSortType, $sortOrder: SortOrder, $filter: PatientFilter) {
	patients(pageIndex:$pageIndex, rowsPerPage:$rowsPerPage, sortBy:$sortBy, sortOrder:$sortOrder, filter:$filter) {
		count
		results {
			id
			firstName
			lastName
			dob
			mrn
			phone
			email
			invitedOn
			activeInterval {
				id
				intervalStart
				intervalEnd
				dataPointMetrics {
					dataPointType
					hasAlerts
					hasThresholds
				}
			}
			lastSync
			watched
			indication
			hasAlerts
		}
	}
}`

export interface IPatientListItem {
	id: ID
	firstName: string
	lastName: string
	dob?: string
	mrn?: string
	phone?: string
	email?: string
	invitedOn: DateTime
	activeInterval?: {
		id: ID
		intervalStart: DateTime
		intervalEnd: DateTime
		dataPointMetrics: Array<{
			dataPointType: DataPointType
			hasAlerts: boolean
			hasThresholds: boolean
		}>
		/*		dataPoints: Array<DataPointType>
			dataPointsWithAlerts?: Array<DataPointType>
			dataPointsWithThresholds?: Array<DataPointType>*/
	}
	lastSync?: DateTime
	watched: boolean
	indication?: string
	hasAlerts: boolean
}

interface IRawQueryResults {
	count: number;
	results: Array<IPatientListItem>;
}

export enum PatientListFilterAlertType {
	All = 'All',
	Alerts = 'Alerts',
	NoAlerts = 'NoAlerts',
	NoThresholds = 'NoThresholds',
}

export interface IPatientListFilter {
	dataPoints?: Array<DataPointType>
	alertStatus?: PatientListFilterAlertType
	nameSearch?: string
}

export enum PatientSortType {
	ID = 'ID',
	Name = 'Name',
	Watched = 'Watched',
	IntervalEnd = 'IntervalEnd',
	LastySync = 'LastySync',
	HasAlerts = 'HasAlerts',
}

export const useGetPatientsList = (pageIndex?: number, rowsPerPage?: number, sortBy?: PatientSortType, sortOrder?: SortOrder, filter?: IPatientListFilter): { loading: boolean; error?: ApolloError; count?: number; patients?: Array<IPatientListItem>; } => {
	const gqlQuery = PATIENTS_LIST_QUERY
	const variables = {
		pageIndex,
		rowsPerPage,
		sortBy,
		sortOrder,
		filter,
	}
	const q = useQuery<{ patients: IRawQueryResults }>(gqlQuery, { fetchPolicy: "cache-first", variables })
	return {
		...q,
		patients: q.data?.patients?.results,
		count: q.data?.patients?.count,
	}
}



const SET_PATIENT_WATCHED = gql`
mutation SetPatientWatched($patientId:ID!, $updates:PatientUpdateInput!) {
	updatePatient(patientId:$patientId, updates:$updates) {
		id
		watched
	}
}`


type SetPatientWatchedDataMutation = [(patientId: string, watched: boolean) => Promise<ExecutionResult>, { loading: boolean; error?: Error; }]
export const useSetPatientWatched = (): SetPatientWatchedDataMutation => {
	const gqlQuery = SET_PATIENT_WATCHED

	const mut = useMutation<{ setPatientWatched: { id: string } }>(gqlQuery, {onError})
	const fn = (patientId: string, watched: boolean): Promise<ExecutionResult> => {
		const variables = { patientId, updates: { watched } }
		return mut[0]({ variables })
	}
	return [fn, mut[1]]
}


export type PatientUpdateInput = {
	watched?: boolean;
	firstName?: string
	lastName?: string
	mrn?: string
	patientNote?: string
	physician?: string
	email?: string
	phone?: string
	sex?: Sex
	gender?: string
	dob?: string
	active?: boolean
	bodyTraceScaleImei?: string
	bodyTraceBpImei?: string
	smartMeterScaleImei?: string
	smartMeterBpImei?: string
}

const UPDATE_PATIENT = gql`
mutation UpdatePatient($patientId:ID!, $updates:PatientUpdateInput!) {
	updatePatient(patientId:$patientId, updates:$updates) {
		id
		watched
		firstName
		lastName
		mrn
		patientNote
		physician
		email
		phone
		sex
		gender
		dob
		active
		bodyTraceScaleImei
		bodyTraceBpImei
		smartMeterScaleImei
		smartMeterBpImei
	}
}`


type UpdatePatientMutation = [(patientId: string, updates: PatientUpdateInput) => Promise<ExecutionResult>, { loading: boolean; error?: Error; called: boolean }]
export const useUpdatePatient = (): UpdatePatientMutation => {
	const gqlQuery = UPDATE_PATIENT

	const mut = useMutation<{ setPatientWatched: { id: string } }>(gqlQuery, { onError, update })
	const fn = (patientId: string, updates: PatientUpdateInput): Promise<ExecutionResult> => {
		let updateObj = pick(updates, ["watched", "firstName", "lastName", "mrn", "patientNote", "indication", "physician", "email", "phone", "sex", "gender", "dob", "active", "bodyTraceScaleImei", "bodyTraceBpImei", "smartMeterScaleImei", "smartMeterBpImei"])
		const variables = { patientId, updates: updateObj }
		return mut[0]({ variables })
	}
	return [fn, mut[1]]
}


export type PatientCreateInput = {
	firstName?: string
	lastName?: string
	email: string
	phone?: string
	sex?: Sex
	dob?: string
}

const CREATE_PATIENT = gql`
mutation CreatePatient($data:PatientCreateInput!) {
	createPatient(data:$data) {
		id
		watched
		firstName
		lastName
		mrn
		patientNote
		physician
		email
		phone
		sex
		gender
		dob
		active
	}
}`


type CreatePatientMutation = [(data: PatientCreateInput) => Promise<ExecutionResult>, { loading: boolean; error?: Error; called: boolean, patientId?: ID }]
export const useCreatePatient = (): CreatePatientMutation => {
	const gqlQuery = CREATE_PATIENT

	const mut = useMutation<{ createPatient: { id: string } }>(gqlQuery, { onError, update })
	const fn = (data: PatientCreateInput): Promise<ExecutionResult> => {

		let updateObj = pick(data, ["firstName", "lastName", "email", "phone", "sex", "dob"])

		const variables = { data: updateObj }
		return mut[0]({ variables })
	}
	let d = {
		...mut[1],
		patientId: mut[1]?.data?.createPatient?.id,
	}
	return [fn, d]
}


const RESEND_INVITE = gql`
mutation ResendInvite($patientId:ID!) {
	resendInvite(patientId:$patientId)
}`

type ResendInviteMutation = [(patientId: string) => Promise<ExecutionResult>, { loading: boolean; error?: Error; called:boolean;}]
export const useResendInvite = (): ResendInviteMutation => {
	const gqlQuery = RESEND_INVITE

	const mut = useMutation<{ resendInvite: boolean }>(gqlQuery, {onError})
	const fn = (patientId: string): Promise<ExecutionResult> => {
		const variables = { patientId }
		return mut[0]({ variables })
	}
	return [fn, mut[1]]
}

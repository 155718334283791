import { ApolloError, useQuery } from '@apollo/client'

import gql from 'graphql-tag'

import { DateTime, SortOrder, ID } from './types'

export type BillingFilter = {

	from?: DateTime

	to?: DateTime

	signedOff?: boolean
}

export enum BillingSortBy {
	ReportNumber = "ReportNumber",
	Billed = "Billed",
	Patient = "Patient",
	IntervalEnd = "IntervalEnd",
}

const BILLING_LIST_QUERY = gql`
query getBillingInfo($filter:BillingFilter, $sortBy:BillingSortBy, $sortOrder:SortOrder, $pageIndex:Int, $rowsPerPage:Int){
	getBillingInfo(filter:$filter, sortBy:$sortBy, sortOrder:$sortOrder, rowsPerPage:$rowsPerPage, pageIndex:$pageIndex) {
		count
		results {
			id
			patient {
				id
				firstName
				lastName
				dob
				mrn
				physician
				indication
			}
			reportNumber
			intervalEnd
			daysOfData
			billed
			canBill
			signoff {
				id
				approvedOn
				cpt_99457
				cpt_99458
				cpt_99458_2
				cpt_99454
				practitioner {
					id
					name
				}
			}
		}
	}
}`

const READY_TO_SIGN_QUERY = gql`
query readyTosign($filter:BillingFilter, $sortBy:BillingSortBy, $sortOrder:SortOrder, $pageIndex:Int, $rowsPerPage:Int){
	getBillingInfo(filter:$filter, sortBy:$sortBy, sortOrder:$sortOrder, rowsPerPage:$rowsPerPage, pageIndex:$pageIndex) {
		count
		results {
			id
			patient {
				id
				firstName
				lastName
				dob
				mrn
				physician
				indication
			}
			reportNote
			hadAlert
			reportNumber
			intervalEnd
			daysOfData
			billed
			signoff {
				id
				approvedOn
				cpt_99457
				cpt_99458
				cpt_99458_2
				cpt_99454
				practitioner {
					id
					name
				}
			}
		}
	}
}`

export interface IBillingListItem {
	id: ID
	reportNumber: number
	intervalEnd: DateTime
	daysOfData: number
	billed: boolean
	canBill: boolean
	hadAlert: boolean
	patient: {
		id: ID
		firstName: string
		lastName: string
		dob: string
		mrn: string
		physician: string
		indication: string
	}
	reportNote?:string
	signoff: Array<{
		id: ID
		approvedOn?: DateTime
		cpt_99457: boolean
		cpt_99458: boolean
		cpt_99458_2: boolean
		cpt_99454: boolean
		practitioner: {
			id: ID
			name: string
		}
	}>
}

interface IRawQueryResults {
	count: number;
	results: Array<IBillingListItem>;
}
let INTVL_END = Date.now()
export const useGetBillingList = (filter: BillingFilter, pageIndex?: number, rowsPerPage?: number, sortBy?: BillingSortBy, sortOrder?: SortOrder): { loading: boolean; error?: ApolloError; count?: number; results?: Array<IBillingListItem>; } => {
	const gqlQuery = BILLING_LIST_QUERY
	const variables = {
		pageIndex,
		rowsPerPage,
		sortBy,
		sortOrder,
		filter:{
			...filter,
			hasData: true,
		},
	}
	const q = useQuery<{ getBillingInfo: IRawQueryResults }>(gqlQuery, { variables })
	return {
		...q,
		results: q.data?.getBillingInfo?.results,
		count: q.data?.getBillingInfo?.count,
	}
}

export const useGetReadyForSignoffList = (): { loading: boolean; error?: ApolloError; results?: Array<IBillingListItem>; } => {
	const gqlQuery = READY_TO_SIGN_QUERY
	const variables = {
		pageIndex: 0,
		rowsPerPage: 100,
		sortBy: BillingSortBy.IntervalEnd,
		sortOrder: SortOrder.Desc,
		filter: {
			to: INTVL_END,
			hasData: true,
			signedOff: false,
		},
	}
	const q = useQuery<{ getBillingInfo: IRawQueryResults }>(gqlQuery, { variables })

	return {
		...q,
		results: q.data?.getBillingInfo?.results,
	}
}

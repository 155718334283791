import React, { FC, useState, useEffect, MouseEvent, ChangeEvent } from 'react'
import Cookies from 'js-cookie'

import { Box, Typography, makeStyles, Button, CircularProgress, Select, MenuItem } from '@material-ui/core'
import { Alert as AlertInfo } from '@material-ui/lab'
import { FormattedNumber } from 'react-intl'
import { useHistory } from 'react-router'
import { TransitionGroup, CSSTransition } from 'react-transition-group'
import { isFinite, groupBy, max, map } from 'underscore'

import { format } from 'utils/date'
import { ID, useGetAlerts, /*useGetAlertCards*/ AlertListData, DataPointType, useDismissAllAlertsForPatient, useDismissAllAlerts, IAlertListFilter, useGetDataPointsInRange } from 'store'
import { ErrorBox, DataPointLabel, SnackbarError, GetDataPointString } from 'components'
import AlertCardGraph from './AlertCardGraph'
import DataPointSelect, { DataPointSelectProps } from 'components/DataPointSelect'
import { getClassificationType } from 'utils'

import ColHeader from '../ColHeader'

const useAlertButtonStyles = makeStyles((theme) => ({
	dismissAll: {
		color: theme.palette.error.contrastText,
		backgroundColor: theme.palette.error.main,
		borderRadius: 0,
	},
	dismissSingle: {
		color: theme.palette.error.contrastText,
		backgroundColor: theme.palette.error.main,
		borderRadius: 0,
	},
	dismissMulti: {
		color: theme.palette.error.contrastText,
		backgroundColor: theme.palette.error.main,
		borderRadius: 0,
	}
}))

const useAlertStyles = makeStyles((theme) => ({
	metric: {
		fontSize: "4.8rem",
		lineHeight: "4.6rem",
		fontWeight: "bold",
	},
	unit: {
		fontSize: "1.4rem",
		fontWeight: 600,
		textTransform: "uppercase",
		marginLeft: "0.2rem"
	},
	label: {
		fontSize: "1.4rem",
		fontWeight: 600,
		textTransform: "uppercase",
	},
	mainAlertBorder: {
		borderLeft: `solid 2rem ${theme.palette.alertRed}`,
	},
	alertSeperatorBorder: {
		border: `solid 0.1rem ${theme.palette.borderColor}`,
	},
	ecgAlertDate:{
		fontSize: "1.4rem",
		lineHeight: "1.4rem",
		fontWeight: 400,
	},
	ecgAlertClassification:{
		fontSize: "2.4rem",
		lineHeight: "2.2rem",
		fontWeight: 400,
		marginTop:"0.5rem",
	},
	ecgAlertSymptoms:{
		fontSize: "1.4rem",
		lineHeight: "1.4rem",
		fontWeight: 900,
		textTransform: "uppercase",
	}
}))

type AlertProps = {
	patientId: string,
	patientName: string,
	alert: AlertListData,
	hasMultipleAlerts?: boolean,
	isFirst?: boolean,
	onDismissed: (patientId: ID, dp: DataPointType) => void
}

const Alert: FC<AlertProps> = ({ onDismissed, patientId, patientName, alert, hasMultipleAlerts, isFirst }) => {
	const styles = useAlertStyles()
	const buttonStyles = useAlertButtonStyles()
	const [dismissAlert, { loading, error, called: alertDismissed }] = useDismissAllAlertsForPatient(patientId)
	const { dataPoints, loading: graphLoading, /*error:graphError*/ } = useGetDataPointsInRange(patientId, undefined, alert.dataPointType, alert.startedOn, alert.endedOn || Date.now())
	const dpType = alert.dataPointType
	const history = useHistory()
	useEffect(() => {
		if (!error && !loading && alertDismissed) {
			onDismissed(patientId, dpType)
		}
	}, [patientId, onDismissed, loading, error, alertDismissed, dpType])

	let dp = alert.dataPointType


	let points = dataPoints?.filter(dp => isFinite(dp.value)) as Array<{ time: number, value: number }> || []

	let maxDeviation = `${toKThousands(alert.alertValue)}`

	const onDismiss = (evt: MouseEvent<HTMLButtonElement>) => {
		evt.stopPropagation()
		if (loading) {
			return
		}
		dismissAlert(alert.dataPointType)
	}

	const onClick = () => {
		history.push(`/patients/${patientId}`)
	}
	const ecgData = alert.ecgReading
	let alertMarkers = alert.markers
	let isEcg = ecgData != null && dp === DataPointType.Ecg
	return (
		<Box style={{ cursor: "pointer" }} onClick={onClick}>
			<SnackbarError error={error} message="Failed to dismiss alert" />
			{!isFirst && <Box borderTop={1} height={2} my={1} mx={1} />}
			<Box display="flex" mt={0.5} mb={1}>
				<Box flex={1}>
					{isFirst && <Box mb={1}>
						<Typography component="div" variant="h6" color="primary">
							{patientName}&nbsp;&nbsp;
							{graphLoading && <CircularProgress size={12} />}
						</Typography>
					</Box>}
				</Box>
				<Box>
					<Button disabled={loading} onClick={onDismiss} variant="contained" className={hasMultipleAlerts ? buttonStyles.dismissMulti : buttonStyles.dismissSingle}>Dismiss</Button>
				</Box>
			</Box>
			{isFirst && <Box my={2} />}
			<Box display="flex">
				<Box>
					<Box display="flex" alignItems="flex-end" width="14rem">
						<Typography variant="body1" className={styles.metric}>
							{maxDeviation}
						</Typography>
						<Typography variant="body1" className={styles.unit}>
							<DataPointLabel field={"unit"} dataPoint={isEcg ? DataPointType.Pulse : dp} />
						</Typography>
					</Box>
					<Box textAlign="center" pl={0}>
						<Typography variant="body1" className={styles.label}>
							<DataPointLabel field={"label"} dataPoint={dp} />
						</Typography>
					</Box>
				</Box>
				{isEcg && <Box flex={1}>
					<Box mt={-2.5} className={styles.ecgAlertDate}>
						{format(alert.startedOn, "P p")}
					</Box>
					{ecgData && (
						<>
							<Box className={styles.ecgAlertClassification}>
								{getClassificationType(ecgData.classification)}
							</Box>
							{ecgData.symptoms?.[0]?.toLowerCase() === "none" && <Box className={styles.ecgAlertSymptoms}>
								<Typography variant="h5">PATIENT REPORTED NO SYMPTOMS</Typography>
							</Box>}
							{!!ecgData.symptoms.length && ecgData.symptoms?.[0]?.toLowerCase() !== "none" && <Box className={styles.ecgAlertSymptoms}>
								<Typography variant="h5">PATIENT REPORTED SYMPTOMS</Typography>
							</Box>}
						</>
					)}
				</Box>}
				{!isEcg && !graphLoading && <Box flex={1}>
					{points.length > 0 && <AlertCardGraph dataPoint={dp} markers={alertMarkers} data={points} thresholdMin={alert.thresholdMin} thresholdMax={alert.thresholdMax} />}
				</Box>}
			</Box>
		</Box>
	)
}


const useAlertGraphTransitionStyles = makeStyles(() => ({
	exit: {
		opacity: 1,
		position: "relative",
		overflow: "hidden",
		height: "13rem",
	},
	exitActive: {
		opacity: 0,
		height: 0,
		position: "relative",
		overflow: "hidden",
		transition: "all 300ms ease-in"
	},
	exitDone: {
		opacity: 0,
		height: 0,
		position: "relative",
		overflow: "hidden",
	}
}))

const AlertCard: FC<{ patientId: string, patientName: string, alerts: Array<AlertListData>, onDismissed: (patientId: ID, dp: DataPointType) => void }> = ({ onDismissed, patientId, patientName, alerts }) => {
	const transitionClassNames = useAlertGraphTransitionStyles()
	const styles = useAlertStyles()

	let alertEls = alerts?.map((a, idx) => {
		return (
			<CSSTransition key={a.id} timeout={400} classNames={transitionClassNames} unmountOnExit>
				<Alert onDismissed={onDismissed} patientId={patientId} patientName={patientName} hasMultipleAlerts={alerts.length > 1} isFirst={idx === 0} key={a.id} alert={a} />
			</CSSTransition>
		)
	})

	return (
		<Box className={styles.alertSeperatorBorder} mb={2.5}>
			<Box borderLeft={20} className={styles.mainAlertBorder} p={1}>
				<TransitionGroup>
					{alertEls}
				</TransitionGroup>
			</Box>
		</Box>
	)
}

const useAlertCardTransitionStyles = makeStyles(() => ({
	exit: {
		opacity: 1,
		position: "relative",
		overflow: "hidden",
		height: "15rem",
	},
	exitActive: {
		opacity: 0,
		height: 0,
		position: "relative",
		overflow: "hidden",
		transition: "all 300ms ease-in"
	},
	exitDone: {
		opacity: 0,
		height: 0,
		position: "relative",
		overflow: "hidden",
	}
}))

const NoItems: FC<{ dataPoints: Array<DataPointType> }> = ({ dataPoints }) => {
	const p2 = {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
	}

	let isAll = dataPoints.length === 0
	let dataPointStr = isAll ? "" : `${dataPoints.map(dp => GetDataPointString(dp)).join(", ")}`

	return (
		<Box {...p2}>
			<AlertInfo severity="info">
				There are currently no alerts {isAll ? "for" : ""}<b>{dataPointStr}</b>
			</AlertInfo>
		</Box>
	)
}

const toKThousands = (val?: number): string => {
	if (val === undefined) {
		return ""
	} else if (val > 10000) {
		return `${Math.floor(val / 1000)}K`
	} else if (val > 1000) {
		return `${Math.floor(val / 100) / 10}K`
	}
	return `${val}`
}

const AlertList: FC = () => {
	const watchedPatientsOnly = Cookies.get('watchedPatientsOnly') === 'true'
	const [filter, setFilter] = useState<IAlertListFilter>({watchedPatientsOnly})
	const transitionClassNames = useAlertCardTransitionStyles()
	const { alerts, loading, error } = useGetAlerts(filter)
	const buttonStyles = useAlertButtonStyles()
	const [dismissAll, { loading: dismissing, error: dismissError }] = useDismissAllAlerts()
	const [dismissedAlerts, setDismissedAlerts] = useState<Array<ID>>([])

	const onDismissed = (patientId: ID, dp: DataPointType) => {
		let newDismissedAlerts = alerts?.filter(a => a.patient.id === patientId && a.dataPointType === dp) || []
		setDismissedAlerts(dismissedAlerts.concat(newDismissedAlerts.map(a => a.id)))
	}
	let allAlertIds = alerts?.map(a => a.id) || []

	let byPatient = groupBy(alerts || [], a => a.patient.id)


	let items = Object.keys(byPatient).map(patientId => {
		let patient = byPatient[patientId][0].patient
		let patientAlerts = byPatient[patientId].filter(a => !a.dismissed && dismissedAlerts.indexOf(a.id) < 0)
		let alertGroupsToRender = groupBy(patientAlerts, a => a.dataPointType)
		let alertsToRender = map(alertGroupsToRender, (vals) => max(vals, v => v.startedOn) as AlertListData)
		if (alertsToRender.length === 0) {
			return undefined
		}
		return (
			<CSSTransition key={patientId} timeout={400} classNames={transitionClassNames} unmountOnExit>
				<AlertCard onDismissed={onDismissed} patientId={patientId} patientName={`${patient.firstName} ${patient.lastName}`} alerts={alertsToRender} />
			</CSSTransition>
		)
	}).filter(a => a !== undefined) || []

	const dataPointsProps: DataPointSelectProps = {
		id: "dataPointFilter",
		fullWidth: true,
		displayEmpty: true,
		multiple: true,
		label: "Data Point:",
		value: filter.dataPoints,
		onChange: (value: Array<DataPointType> | DataPointType) => {
			let dataPoints = value as Array<DataPointType>
			setFilter({ ...filter, dataPoints })
		},
	}

	const onChangePatientFilter = (evt: ChangeEvent<{value:unknown}>)=> {
		const watchedPatientsOnly = evt.target.value === "watched"
		Cookies.set('watchedPatientsOnly', watchedPatientsOnly ? 'true' : 'false')
		setFilter({
			...filter,
			watchedPatientsOnly
		})
	}

	return (
		<>
			<ColHeader title="Alerts" loading={loading} />
			<Box flex={1} display="flex" flexDirection="column">
				<SnackbarError error={dismissError} message="Failed to dismiss alerts" />
				<Box display="flex" my={3}>
					<Box>
						<DataPointSelect {...dataPointsProps} />
					</Box>
					<Box mx={2}>
						<Select value={filter.watchedPatientsOnly ? "watched" : "all"} onChange={onChangePatientFilter}>
							<MenuItem value="watched">My Patients</MenuItem>
							<MenuItem value="all">All Patients</MenuItem>
						</Select>
					</Box>
					<Box flex={1} />
					<Box>
						{items.length > 0 && <Button disabled={loading || dismissing} onClick={() => dismissAll(allAlertIds)} className={buttonStyles.dismissAll} variant="contained">Dismiss All</Button>}
					</Box>
					{!loading && <Box alignItems="flex-end" display="flex" ml={1}><Typography variant="h6"><FormattedNumber value={items.length} /></Typography></Box>}
				</Box>
				<Box flex={1} position="relative" flexDirection="column" display="flex" justifyContent="center" alignItems="center">
					{!!error && <ErrorBox my={2} message="Failed to load alerts" />}
					{!loading && !error && items.length === 0 && <NoItems dataPoints={filter.dataPoints || []} />}
					<Box position="absolute" left="0" right="-2rem" pr="2rem" height="100%" overflow="visible auto">
						<TransitionGroup>
							{items}
						</TransitionGroup>
					</Box>
				</Box>
			</Box>
		</>
	)
}

export default AlertList

import React, { FC, useState, useEffect, useRef, MutableRefObject, ChangeEvent, useCallback, useLayoutEffect } from 'react';

import { Box, Button, Dialog, DialogTitle, DialogActions, CircularProgress, makeStyles, useTheme, Select, MenuItem, FormControl, InputLabel, Tabs, Tab, Table, TableHead, TableRow, TableCell, TableBody } from '@material-ui/core'
import { min, isFinite, sortBy, pluck, last, max, uniq } from 'underscore'
import { VictoryTheme, VictoryAxis, VictoryChart, VictoryLine, VictoryLabel, VictoryChartProps, VictoryLabelProps, VictoryLineProps, VictoryBrushContainer, VictoryZoomContainerProps, VictoryBrushContainerProps, VictoryScatterProps, VictoryScatter, createContainer, VictoryVoronoiContainerProps, VictoryTooltip } from 'victory';

import { format } from 'utils/date'
import { DataPointType, ID, DateTime, useGetPatientGraphData, IPatientInfo } from 'store'
import { DataPointLabel, GraphAlertMarker, ErrorBox, GetDataPointString, GraphPointMarker } from 'components'
import { KeyboardDatePicker } from '@material-ui/pickers';

const HOUR_MS = 60 * 60 * 1000
const DAY_MS = 86400000
const WEEK_MS = 7 * 86400000

type InterpolationPropType =
	| "basis"
	| "basisClosed"
	| "basisOpen"
	| "bundle"
	| "cardinal"
	| "cardinalClosed"
	| "cardinalOpen"
	| "catmullRom"
	| "catmullRomClosed"
	| "catmullRomOpen"
	| "linear"
	| "linearClosed"
	| "monotoneX"
	| "monotoneY"
	| "natural"
	| "radial"
	| "step"
	| "stepAfter"
	| "stepBefore";

const INTERPOLATION_TYPE: InterpolationPropType = "monotoneX"
const useDimensions = (): [MutableRefObject<HTMLDivElement>, { width?: number, height?: number }] => {
	const ref = useRef<HTMLDivElement>() as MutableRefObject<HTMLDivElement>
	const [dimensions, setDimensions] = useState<{ width?: number, height?: number }>({
		width: undefined,
		height: undefined,
	})

	useLayoutEffect(() => {
		let maxWidth: number = 999999
		let maxHeight: number | undefined = undefined
		let handleResize = () => {
			if (ref.current) {
				const { width, height } = ref.current.getBoundingClientRect().toJSON()
				setDimensions({ width: width === undefined ? undefined : Math.min(maxWidth, width), height: maxHeight === undefined ? height : maxHeight })
			}
		}

		const beforePrint = () => {
			maxWidth = 950 // 900px max print width for victory charts
			maxHeight = 550
			handleResize()
		}

		const afterPrint = () => {
			maxWidth = 999999
			maxHeight = undefined
			handleResize()
		}

		window.addEventListener("beforeprint", beforePrint)
		window.addEventListener("afterprint", afterPrint)
		window.addEventListener("resize", handleResize)
		handleResize()
		return () => {
			window.removeEventListener("beforeprint", beforePrint)
			window.removeEventListener("afterprint", afterPrint)
			window.removeEventListener("resize", handleResize)
		}
	}, [ref])

	return [ref, dimensions]
}

interface DataPoint {
	time: DateTime
	value?: number
}

interface Threshold {
	id: ID
	min?: number
	max?: number
	createdOn: DateTime
}

interface MarkerPoint {
	time: DateTime,
	value: number
}

type DataGraphProps = {
	onChangeTimeScale: (scale: TimeScale) => void
	selectedTimeScale: TimeScale
	lineColor: string
	secondaryLineColor?: string
	thresholds: Array<Threshold>
	dataPoints: Array<DataPoint>
	dataPointType: DataPointType
	markers: Array<MarkerPoint>

	secondaryDataPointType?: DataPointType
	secondaryDataPoints?: Array<DataPoint>
	secondaryThresholds?: Array<Threshold>

	graphStyle: GraphStyle

	timeBounds: { start: number, end: number }

}

const nullOrUndef = (val?: number) => {
	return val === null || val === undefined
}

const buildThresholdPoints = (thresholds: Array<Threshold>, point: "min" | "max"): Array<DataPoint> => {
	let points = thresholds.reduce((prev: Array<DataPoint>, curr: Threshold) => {
		let lastPoint = last(prev)
		let myPoint = { time: curr.createdOn, value: curr[point] }
		if (!lastPoint) {
			return [myPoint]
		}
		let bridgePoint = { ...lastPoint, time: curr.createdOn - 1 }
		return prev.concat([bridgePoint, myPoint])
	}, [])
	let lastThreshold = last(thresholds)
	if (lastThreshold && !nullOrUndef(lastThreshold?.[point])) {
		points = points.concat([{ time: Date.now(), value: lastThreshold[point] }])
	}
	return points
}


const getTimeRange = (scale: TimeScale, timeBounds: { start: number, end: number }): number | undefined => {
	switch (scale) {
		case "hour":
			return HOUR_MS
		case "week":
			return WEEK_MS
		case "day":
			return DAY_MS
		case "all":
			return timeBounds.end - timeBounds.start
		default:
			return undefined
	}
}

const calcTimeScale = (xDomain: Array<number>): TimeScale => {
	let domain = Math.floor((xDomain[1] - xDomain[0]) / 60000) * 60000  // only care about "minute" precision here
	if (domain === HOUR_MS) {
		return "hour"
	} else if (domain === DAY_MS) {
		return "day"
	} else if (domain === WEEK_MS) {
		return "week"
	} else {
		return "all"
	}
}

const PAD_LEFT = 84
const PAD_RIGHT = 84


const SCRUBBER_HEIGHT = 95

type ScrubberProps = {
	minPoint: number,
	maxPoint: number
	minYVal: number,
	maxYVal: number,
	width: number,
	handleBrush: (domain: any) => void
	data: Array<DataPoint>,
	secondaryData?: Array<DataPoint>,
	selectedDomain: { x: [number, number], y: [number, number] }
	graphStyle: GraphStyle
}

const Scrubber: FC<ScrubberProps> = ({ data, width, minPoint, maxPoint, minYVal, maxYVal, handleBrush, selectedDomain, graphStyle, secondaryData }) => {
	const muiTheme = useTheme()
	const lineColor = muiTheme.palette.alertRed//.dataPlot.line
	const [downsampled, setDownsampled] = useState<Array<DataPoint>>([])
	const [downsampledSecondary, setDownsampledSecondary] = useState<Array<DataPoint>>([])

	useEffect(() => {
		let samples: Array<DataPoint> = [data[0]]
		let lastPoint = data[1]
		let dir: "incr" | "decr" = "incr"
		for (let i = 2; i < data.length; i++) {
			let point = data[i]
			if (point.value === undefined) {
				continue
			}
			let lastValue = (lastPoint.value || 0)
			if (point.value < lastValue && dir === "incr") {
				// inflection point
				samples.push(lastPoint)
				dir = "decr"
			} else if (point.value > lastValue && dir === "decr") {
				// inflection point
				samples.push(lastPoint)
				dir = "incr"
			}
			lastPoint = point
		}
		setDownsampled(samples)

	}, [data])

	useEffect(() => {
		if (!secondaryData) {
			setDownsampledSecondary([])
			return
		}
		let samples: Array<DataPoint> = [secondaryData[0]]
		let lastPoint = secondaryData[1]
		let dir: "incr" | "decr" = "incr"
		for (let i = 2; i < secondaryData.length; i++) {
			let point = secondaryData[i]
			if (point.value === undefined) {
				continue
			}
			let lastValue = (lastPoint.value || 0)
			if (point.value < lastValue && dir === "incr") {
				// inflection point
				samples.push(lastPoint)
				dir = "decr"
			} else if (point.value > lastValue && dir === "decr") {
				// inflection point
				samples.push(lastPoint)
				dir = "incr"
			}
			lastPoint = point
		}
		setDownsampledSecondary(samples)

	}, [secondaryData])

	let brushContainerContainrProps: VictoryBrushContainerProps = {
		responsive: false,
		brushDimension: "x",
		brushDomain: selectedDomain,
		onBrushDomainChange: handleBrush,
		defaultBrushArea: "move",
		brushStyle: { stroke: "transparent", fill: muiTheme.palette.primary.light, fillOpacity: 0.5 }
	}

	let lineStyle = {
		data: { stroke: lineColor, strokeWidth: 1.25 },
	}

	let scrubberChartProps: VictoryChartProps = {
		domain: { x: [minPoint, maxPoint], y: [minYVal, maxYVal] },
		width,
		height: SCRUBBER_HEIGHT,
		padding: { top: 10, right: PAD_RIGHT, left: PAD_LEFT, bottom: 25 },
		scale: { x: "linear" },
		containerComponent: <VictoryBrushContainer {...brushContainerContainrProps} />
	}

	let plotLines
	let secondaryPlotLines
	let primaryInterpolation
	let secondaryInterpolation

	if (graphStyle === "line") {
		let lProps: VictoryLineProps = {
			style: lineStyle,
			data: downsampled?.filter(dp => dp?.value !== undefined),
			x: "time",
			y: "value",
		}
		plotLines = <VictoryLine  {...lProps} />

		let secondaryLineProps: VictoryLineProps = {
			style: lineStyle,
			data: downsampledSecondary.filter(dp => dp.value !== undefined),
			x: "time",
			y: "value",
		}
		secondaryPlotLines = <VictoryLine  {...secondaryLineProps} />

	} else if (graphStyle === "plot" || graphStyle === "interpolation") {

		let pDp = data.filter(dp => dp.value !== undefined)
		let sDp = secondaryData?.filter(dp => dp.value !== undefined) || []

		let lProps: VictoryScatterProps = {
			style: lineStyle,
			data: pDp,
			x: "time",
			y: "value",
			dataComponent: <GraphPointMarker color={lineStyle.data.stroke} />
		}
		plotLines = <VictoryScatter  {...lProps} />

		let secondaryLineProps: VictoryScatterProps = {
			style: lineStyle,
			data: sDp,
			x: "time",
			y: "value",
			dataComponent: <GraphPointMarker color={lineStyle.data.stroke} />
		}
		secondaryPlotLines = <VictoryScatter  {...secondaryLineProps} />

		if (graphStyle === "interpolation") {

			if (pDp.length > 1) {
				let lProps: VictoryLineProps = {
					style: lineStyle,
					data: pDp,
					x: "time",
					y: "value",
					interpolation: INTERPOLATION_TYPE
				}
				primaryInterpolation = <VictoryLine  {...lProps} />
			}

			if (sDp.length > 1) {
				let secondaryLineProps: VictoryLineProps = {
					style: lineStyle,
					data: sDp,
					x: "time",
					y: "value",
					interpolation: INTERPOLATION_TYPE
				}
				secondaryInterpolation = <VictoryLine  {...secondaryLineProps} />
			}

		}

	}

	/*
		let fullLineProps: VictoryLineProps = {
			style: lineStyle,
			data: downsampled,
			x: "time",
			y: "value",
		}
		let plotLineFull = <VictoryLine  {...fullLineProps} />
	*/

	return (
		<VictoryChart {...scrubberChartProps}>
			<VictoryAxis domain={[minPoint, maxPoint]} tickLabelComponent={<></>} />
			{plotLines}
			{secondaryPlotLines}
			{primaryInterpolation}
			{secondaryInterpolation}
		</VictoryChart>
	)
}

class CustomTooltip extends React.Component {
	render() {
		var props = { ...this.props } as any
		if (props.activePoints.length > 0) {
			props.activePoints = [props.activePoints[0]]
			props.style = [props.style[0]]
			props.text = [props.text[0]]
		}
		return <VictoryTooltip {...props} />
	}
}


const VictoryZoomVoronoiContainer = createContainer<VictoryZoomContainerProps, VictoryVoronoiContainerProps>("zoom", "voronoi")

const DatatGraph: FC<DataGraphProps> = ({ timeBounds, graphStyle, selectedTimeScale, onChangeTimeScale, markers, lineColor, dataPointType, dataPoints = [], thresholds = [], secondaryLineColor, secondaryDataPoints, secondaryThresholds }) => {
	const [ref, dimensions] = useDimensions()
	const [graphedData, setGraphedData] = useState<Array<DataPoint>>([])
	const [secondaryGraphedData, setSecondaryGraphedData] = useState<Array<DataPoint>>([])

	const muiTheme = useTheme()
	let currThreshold = sortBy(thresholds, "createdOn").reverse()[0]
	let currThresholdMin = isFinite(currThreshold?.min) ? currThreshold?.min : undefined
	let currThresholdMax = isFinite(currThreshold?.max) ? currThreshold?.max : undefined

	let currSecondaryThreshold = sortBy(secondaryThresholds || [], "createdOn").reverse()[0]
	let currSecondaryThresholdMin = isFinite(currSecondaryThreshold?.min) ? currSecondaryThreshold?.min : undefined
	let currSecondaryThresholdMax = isFinite(currSecondaryThreshold?.max) ? currSecondaryThreshold?.max : undefined

	let now = timeBounds.end
	const timeRange = getTimeRange(selectedTimeScale, timeBounds)

	const [selectedDomain, setSelectedDomain] = useState<any>({ x: [now - (timeRange || DAY_MS), now] })
	const [zoomDomain, setZoomDomain] = useState<any>({ x: [now - (timeRange || DAY_MS), now] })

	const thresholdRed = muiTheme.palette.alertRed

	let allValidPoints = sortBy(dataPoints.concat(secondaryDataPoints || []).filter(d => d.value !== undefined), d => d.time)
	let allTimes = pluck(allValidPoints, "time")
	if (allTimes.length < 2) {
		allTimes = [now - DAY_MS, now]
	}

	let minYVal = (min(allValidPoints, v => v.value) as DataPoint)?.value
	let maxYVal = (max(allValidPoints, v => v.value) as DataPoint)?.value

	if (dataPointType === DataPointType.Sleep) {
		minYVal = 0
		maxYVal = 24
	}

	minYVal = minYVal === undefined ? 0 : minYVal
	maxYVal = maxYVal === undefined ? 100 : maxYVal


	const thresholdPoints: Array<number> = []

	if (currThresholdMin !== undefined) {
		minYVal = Math.min(minYVal, currThresholdMin)
		thresholdPoints.push(currThresholdMin)
	}

	if (currSecondaryThresholdMin !== undefined) {
		minYVal = Math.min(minYVal, currSecondaryThresholdMin)
		thresholdPoints.push(currSecondaryThresholdMin)
	}

	if (currThresholdMax !== undefined) {
		maxYVal = Math.max(maxYVal, currThresholdMax)
		thresholdPoints.push(currThresholdMax)
	}

	if (currSecondaryThresholdMax !== undefined) {
		maxYVal = Math.max(maxYVal, currSecondaryThresholdMax)
		thresholdPoints.push(currSecondaryThresholdMax)
	}

	let minPoint: number = Math.min(min(allTimes) || now, now - (timeRange || DAY_MS)) - (3 * 60 * 60 * 1000) // give a 3 hour buffer
	let maxPoint = now

	const updateVisibleGraphData = useCallback((minPoint: number, maxPoint: number) => {
		const computeData = (d: Array<DataPoint>, fnSetter: (val: Array<DataPoint>) => void) => {
			// need to also include the first point "before" the range and the first point after it
			// otherwise you might end up with just a single point and no lines (e.g. sleep with under a days view)
			let allValidPoints = sortBy(d.filter(d => d.value !== undefined), d => d.time)

			let filteredPoints = allValidPoints.filter(d => d.time >= minPoint && d.time <= maxPoint)
			if (d.length < 2) {
				fnSetter(filteredPoints)
				return
			}

			let lastPointBeforeRange = last(allValidPoints.filter(d => d.time < minPoint))
			let firstPointAfterRange = allValidPoints.find(d => d.time > maxPoint)

			if (lastPointBeforeRange) {
				filteredPoints = [lastPointBeforeRange].concat(filteredPoints)
			}
			if (firstPointAfterRange) {
				filteredPoints = filteredPoints.concat([firstPointAfterRange])
			}
			fnSetter(filteredPoints)
		}
		computeData(dataPoints, setGraphedData)
		if (secondaryDataPoints) {
			computeData(secondaryDataPoints, setSecondaryGraphedData)
		} else {
			setSecondaryGraphedData([])
		}
	}, [dataPoints, secondaryDataPoints])

	const hasGraphData = !!graphedData.length || !!secondaryGraphedData?.length
	useEffect(() => {
		if (maxPoint === now) {
			if (!hasGraphData) {
				updateVisibleGraphData(minPoint, maxPoint)
			}
			return
		}
		//let initMin = Math.max(minPoint, maxPoint - timeRange)
		setSelectedDomain({ x: [minPoint, maxPoint], y: [minYVal, maxYVal] })
		setZoomDomain({ x: [minPoint, maxPoint], y: [minYVal, maxYVal] })
		updateVisibleGraphData(minPoint, maxPoint)
	}, [now, minPoint, maxPoint, minYVal, maxYVal, updateVisibleGraphData, hasGraphData])


	const selectedXDomain = selectedDomain.x

	useEffect(() => {
		if (!selectedTimeScale || !timeRange) {
			return
		}
		let scale = calcTimeScale(selectedXDomain)
		if (scale === selectedTimeScale) {
			return
		}
		let domainX = selectedXDomain.concat([]) // copy it
		// try to subtract from our current end point (so the right side stays locked)
		// if we're too far back in history we reach the end, and have to expand back to the future to fill the range
		domainX[0] = Math.max(minPoint, domainX[1] - timeRange)
		domainX[1] = domainX[0] + timeRange
		setSelectedDomain({ x: domainX, y: [minYVal, maxYVal] })
		setZoomDomain({ x: domainX, y: [minYVal, maxYVal] })
		updateVisibleGraphData(domainX[0], domainX[1])

	}, [updateVisibleGraphData, minPoint, selectedTimeScale, selectedXDomain, minYVal, maxYVal, dataPoints, timeRange])

	let thresholdStyle = {
		data: { stroke: thresholdRed, strokeWidth: 1.0 },
	}

	let lineStyle = {
		data: { stroke: lineColor, strokeWidth: 1.25 },
	}

	let secondaryLineStyle = {
		data: { stroke: secondaryLineColor || lineColor, strokeWidth: 1.25 },
	}

	let theme = {
		...VictoryTheme.material,
		axis: {
			...VictoryTheme.material.axis,
			"style": {
				...VictoryTheme.material.axis?.style,
				axis: {
					stroke: "transparent"
				},
				ticks: {
					stroke: "transparent"
				},
				grid: {
					...VictoryTheme.material.axis?.style?.grid,
					"stroke": muiTheme.palette.dataPlot.gridLineStroke,
					"strokeWidth": 1,
					"strokeDasharray": "1, 1",
				},
			}
		}
	}

	const handleZoom = (domain: any): void => {
		onChangeTimeScale(calcTimeScale(domain.x))
		setSelectedDomain({ ...domain, y: [minYVal, maxYVal] })
		setZoomDomain({ ...domain, y: [minYVal, maxYVal] })
		updateVisibleGraphData(domain.x[0], domain.x[1])
	}

	const handleBrush = (domain: any): void => {
		onChangeTimeScale(calcTimeScale(domain.x))
		setZoomDomain({ ...domain, y: [minYVal, maxYVal] })
		setSelectedDomain({ ...domain, y: [minYVal, maxYVal] })
		updateVisibleGraphData(domain.x[0], domain.x[1])
	}


	let zoomContainerProps = {
		responsive: false,
		zoomDimension: "x" as const,
		zoomDomain,
		onZoomDomainChange: handleZoom,
		labelComponent: <CustomTooltip />,
		labels: ({ datum }: any) => {
			if (dataPointType === DataPointType.StepCounter || dataPointType === DataPointType.Sleep) {
				return `${datum.time ? format(datum.time, 'P') : ''}, ${GetDataPointString(dataPointType, "unit", datum.value)}`
			} else {
				return `${datum.time ? format(datum.time, 'P p') : ''}, ${GetDataPointString(dataPointType, "unit", datum.value)}`
			}
		}
	}

	let chartProps: VictoryChartProps = {
		theme,
		domain: { x: [minPoint, maxPoint], y: [minYVal, maxYVal] },
		width: dimensions.width || 500,
		height: (dimensions.height || 200) - SCRUBBER_HEIGHT,
		padding: { top: 35, bottom: 35, right: PAD_RIGHT, left: PAD_LEFT },
		containerComponent: <VictoryZoomVoronoiContainer {...zoomContainerProps} />
	}

	const TickLabel: FC<VictoryLabelProps> = (props) => {

		if (thresholdPoints.indexOf(props.datum as number) > -1 || props.text === `${currThresholdMin}` || props.text === `${currThresholdMax}` || props.text === `${currSecondaryThresholdMin}` || props.text === `${currSecondaryThresholdMax}`) {
			return <VictoryLabel {...props} style={[{ fill: thresholdRed }]} />
		}
		return <VictoryLabel {...props} style={[{ fill: muiTheme.palette.text.primary }]} />
	}

	const EmptyComponent: FC = () => {
		return <></>
	}

	let plotLines
	let secondaryPlotLines
	let primaryInterpolation
	let secondaryInterpolation

	let pDp = uniq(dataPoints.filter(dp => dp.value !== undefined), dp => dp.time)
	let sDp = uniq(secondaryGraphedData.filter(dp => dp.value !== undefined), dp => dp.time)

	if (graphStyle === "line") {
		let lProps: VictoryLineProps = {
			style: lineStyle,
			data: pDp,
			x: "time",
			y: "value",
		}
		plotLines = <VictoryLine  {...lProps} />

		let secondaryLineProps: VictoryLineProps = {
			style: lineStyle,
			data: secondaryGraphedData.filter(dp => dp.value !== undefined),
			x: "time",
			y: "value",
		}
		secondaryPlotLines = <VictoryLine  {...secondaryLineProps} />

	} else if (graphStyle === "plot" || graphStyle === "interpolation") {

		let lProps: VictoryScatterProps = {
			style: lineStyle,
			data: pDp,
			x: "time",
			y: "value",
			dataComponent: <GraphPointMarker color={lineStyle.data.stroke} large />
		}
		plotLines = <VictoryScatter  {...lProps} />

		let secondaryLineProps: VictoryScatterProps = {
			style: secondaryLineStyle,
			data: sDp,
			x: "time",
			y: "value",
			dataComponent: <GraphPointMarker color={secondaryLineStyle.data.stroke} large />
		}
		secondaryPlotLines = <VictoryScatter  {...secondaryLineProps} />

		if (graphStyle === "interpolation") {

			if (pDp.length > 1) {
				let lProps: VictoryLineProps = {
					style: lineStyle,
					data: pDp,
					x: "time",
					y: "value",
					interpolation: INTERPOLATION_TYPE
				}
				primaryInterpolation = <VictoryLine  {...lProps} />
			}

			if (sDp.length > 1) {
				let secondaryLineProps: VictoryLineProps = {
					style: lineStyle,
					data: sDp,
					x: "time",
					y: "value",
					interpolation: INTERPOLATION_TYPE
				}
				secondaryInterpolation = <VictoryLine  {...secondaryLineProps} />
			}

		}
	}


	thresholds = sortBy(thresholds, "createdOn")
	secondaryThresholds = sortBy(secondaryThresholds || [], "createdOn")


	let thresholdMinPoints = buildThresholdPoints(thresholds, "min")
	let thresholdMaxPoints = buildThresholdPoints(thresholds, "max")

	let thresholdSecondaryMinPoints = buildThresholdPoints(secondaryThresholds, "min")
	let thresholdSecondaryMaxPoints = buildThresholdPoints(secondaryThresholds, "max")

	let tminLineProps: VictoryLineProps = {
		style: thresholdStyle,
		data: thresholdMinPoints,
		x: "time",
		y: "value",
	}
	let thresholdMinLines = thresholdMinPoints.length ? <VictoryLine {...tminLineProps} /> : <></>
	let tmaxLineProps: VictoryLineProps = {
		style: thresholdStyle,
		data: thresholdMaxPoints,
		x: "time",
		y: "value",
	}
	let thresholdMaxLines = thresholdMaxPoints.length ? <VictoryLine {...tmaxLineProps} /> : <></>

	let tSecondaryMinLineProps: VictoryLineProps = {
		style: thresholdStyle,
		data: thresholdSecondaryMinPoints,
		x: "time",
		y: "value",
	}
	let thresholdSecondaryMinLines = thresholdSecondaryMinPoints.length ? <VictoryLine {...tSecondaryMinLineProps} /> : <></>

	let tSecondaryMaxLineProps: VictoryLineProps = {
		style: thresholdStyle,
		data: thresholdSecondaryMaxPoints,
		x: "time",
		y: "value",
	}
	let thresholdSecondaryMaxLines = thresholdSecondaryMaxPoints.length ? <VictoryLine {...tSecondaryMaxLineProps} /> : <></>


	let markersProps: VictoryScatterProps = {
		data: markers,
		x: "time",
		y: "value",
		dataComponent: <GraphAlertMarker size="large" />
	}

	const scrubberProps: ScrubberProps = {
		selectedDomain,
		handleBrush,
		width: dimensions.width || 500,
		minPoint,
		maxPoint,
		minYVal,
		maxYVal,
		data: dataPoints,
		graphStyle,
		secondaryData: secondaryDataPoints,
	}

	let xTimeScale = selectedDomain.x[1] - selectedDomain.x[0]

	let tickFormat = "P"
	if (xTimeScale < 0.5 * 86400000) {
		// show minutes level data
		tickFormat = "P h:mm a"
	} else if (xTimeScale < 3 * 86400000) {
		// show hours level data
		tickFormat = "P ha"
	} else {
		// show days level data
		tickFormat = "P"
	}

	return (
		<div style={{ flex: 1, width: "100%" }} ref={ref} >
			<VictoryChart {...chartProps}>
				<VictoryAxis dependentAxis tickLabelComponent={<TickLabel />} />

				{thresholdPoints.length > 0 && <VictoryAxis orientation="right" gridComponent={<EmptyComponent />} dependentAxis tickValues={thresholdPoints} tickLabelComponent={<TickLabel />} tickFormat={(x: number) => x.toLocaleString()} />}

				<VictoryAxis domain={[minPoint, maxPoint]} gridComponent={<EmptyComponent />} tickLabelComponent={<TickLabel />} tickFormat={(x) => format(new Date(x), tickFormat)} />
				{primaryInterpolation}
				{plotLines}
				{secondaryInterpolation}
				{secondaryPlotLines}
				{thresholdMinLines}
				{thresholdMaxLines}
				{thresholdSecondaryMinLines}
				{thresholdSecondaryMaxLines}
				<VictoryScatter {...markersProps} />
			</VictoryChart>
			<Scrubber {...scrubberProps} />

		</div >
	)
}

export type GraphStyle = "line" | "plot" | "interpolation"

const useStyles = makeStyles(() => ({
	dialogPaper: {
		minHeight: '90vh',
		maxHeight: '90vh',
	},
}))


const getDefautTimeScale = (intervalStart: number): TimeScale => {
	const now = Date.now()
	if (now - intervalStart < DAY_MS) {
		return "day"
	} else if (now - intervalStart < 7 * DAY_MS) {
		return "week"
	} else {
		return "week"
	}
}

type DataTableViewProps = {
	patient: IPatientInfo
	dataPointType: DataPointType
	secondaryDataPointType?: DataPointType
	since: DateTime
}

const DAYS_30 = 30 * 86400000
const DataTableView: FC<DataTableViewProps> = ({ dataPointType, secondaryDataPointType, patient, since }) => {
	const tRange = { startTime: since - DAYS_30, endTime: since }
	const { graphData, error: primaryError, loading: primaryLoading } = useGetPatientGraphData(patient.id, undefined, dataPointType, tRange)
	const { graphData: secondaryGraphData, error: secondaryError, loading: secondaryLoading } = useGetPatientGraphData(patient.id, undefined, secondaryDataPointType, tRange)

	const error = primaryError || secondaryError
	const loading = primaryLoading || secondaryLoading

	const dataPoints = graphData?.dataPoints || []
	const secondaryDataPoints = secondaryGraphData?.dataPoints || []

	const hasSecondary = !!secondaryDataPointType

	const allTimesUnsorted = uniq(dataPoints.map(dp => dp.time).concat(secondaryDataPoints.map(dp => dp.time)))
	const allTimes = allTimesUnsorted.sort((a: number, b: number) => b - a)

	if (error) {
		return <ErrorBox message="Failed to load data" />
	} else if (loading || !graphData) {
		return <CircularProgress color="primary" size="34" />
	}

	const dataRows = allTimes.map(t => {
		const prime = dataPoints.find(dp => dp.time === t)
		const secondary = secondaryDataPoints?.find(dp => dp.time === t)
		return (
			<TableRow key={t}>
				<TableCell style={{fontSize:'1.6rem', padding:'4px'}}>
					{format(t, "P")}
				</TableCell>
				<TableCell style={{fontSize:'1.6rem', padding:'4px'}}>
					{format(t, "p")}
				</TableCell>
				<TableCell style={{fontSize:'1.6rem', padding:'4px'}}>
					{prime !== undefined && GetDataPointString(dataPointType, "unit", prime.value)}
				</TableCell>
				{hasSecondary && <TableCell style={{fontSize:'1.6rem', padding:'4px'}}>{secondary !== undefined && GetDataPointString(dataPointType, "unit", secondary?.value)}</TableCell>}
			</TableRow>
		)
	})

	return (
		<Box position="relative" flex={1} overflow="hidden" width="100%" className="print30DayBlock">
			<Box position="absolute" overflow="auto" width="100%" height="100%" p={2} className="print30DayBlock">
				<Table>
					<TableHead>
						<TableRow>
							<TableCell>Date</TableCell>
							<TableCell>Time</TableCell>
							<TableCell>{GetDataPointString(dataPointType, "label")}</TableCell>
							{hasSecondary && !!secondaryDataPointType && <TableCell>{GetDataPointString(secondaryDataPointType, "label")}</TableCell>}
						</TableRow>
					</TableHead>
					<TableBody>
						{dataRows}
					</TableBody>
				</Table>
			</Box>
		</Box>
	)
}

type GraphViewProps = {
	selectedTimeScale: TimeScale
	dataPointType: DataPointType
	endDate: DateTime;
	secondaryDataPoint?: DataPointType;
	patient: IPatientInfo;
	intervalId: ID
	graphStyle: GraphStyle
	onSetSelectedTimeScale: (ts: TimeScale) => void

}

const MAX_INTERVAL_DAY_MS = DAY_MS * 31

const GraphView: FC<GraphViewProps> = ({ dataPointType, selectedTimeScale, endDate, secondaryDataPoint, patient, intervalId, graphStyle, onSetSelectedTimeScale }) => {

	const theme = useTheme()
	const now = Date.now()
	const patientInterval = patient.intervals.find(i => i.id === intervalId)

	const timeRange = {
		start: patientInterval?.intervalStart || (now - 30 * 86400000),
		end: Math.min(now, patientInterval?.intervalEnd || now),
	}
	
	const { graphData, error: primaryError, loading: primaryLoading } = useGetPatientGraphData(patient.id, undefined, dataPointType, { endTime: endDate, startTime: endDate - MAX_INTERVAL_DAY_MS, includeDismissedAlerts: true })
	const { graphData: secondaryGraphData, error: secondaryError, loading: secondaryLoading } = useGetPatientGraphData(patient.id, undefined, secondaryDataPoint, { endTime: endDate, startTime: endDate - MAX_INTERVAL_DAY_MS, includeDismissedAlerts: true })

	const error = primaryError || secondaryError
	const loading = primaryLoading || secondaryLoading

	const thresholds = sortBy(graphData?.thresholds || [], "createdOn").reverse()
	const secondaryThresholds = sortBy(secondaryGraphData?.thresholds || [], "createdOn").reverse()
	const alerts = sortBy((graphData?.alerts || []).concat(secondaryGraphData?.alerts || []), "startedOn").reverse()
	const activeAlerts = alerts.filter(a => !a.dismissed)

	let { min: thresholdMin = -Infinity, max: thresholdMax = Infinity } = thresholds?.[0] || {}
	let { min: thresholdSecondaryMin = -Infinity, max: thresholdSecondaryMax = Infinity } = secondaryThresholds?.[0] || {}

	useEffect(() => {
		onSetSelectedTimeScale(getDefautTimeScale(timeRange.start))
	}, [onSetSelectedTimeScale, dataPointType, timeRange.start])


	let allPoints = graphData?.dataPoints || []
	let allValidPoint = allPoints.filter(p => p.value !== undefined) as Array<{ time: DateTime, value: number }>

	let allSecondaryPoints = secondaryGraphData?.dataPoints || []
	let allValidSecondaryPoint = allSecondaryPoints.filter(p => p.value !== undefined) as Array<{ time: DateTime, value: number }>

	let mostRecentDataPoint = sortBy(allValidPoint, "time").reverse()[0]?.value
	let mostRecentSecondaryDataPoint = sortBy(allValidSecondaryPoint, "time").reverse()[0]?.value

	let hasAlerts = activeAlerts.length > 0

	let lineColor = theme.palette.dataPlot.line
	if (hasAlerts) {
		lineColor = theme.palette.dataPlot.lineWarn
	} else if (thresholds.length > 0 && mostRecentDataPoint !== undefined) {
		if ((!isFinite(thresholdMin) || thresholdMin < mostRecentDataPoint) && (!isFinite(thresholdMax) || thresholdMax > mostRecentDataPoint)) {
			// no warn
			lineColor = theme.palette.dataPlot.line
		} else {
			lineColor = theme.palette.dataPlot.lineWarn
		}
	}

	let secondaryLineColor = theme.palette.dataPlot.line
	if (hasAlerts) {
		secondaryLineColor = theme.palette.dataPlot.lineWarn
	} else if (secondaryThresholds.length > 0 && mostRecentSecondaryDataPoint !== undefined) {
		if ((!isFinite(thresholdSecondaryMin) || thresholdSecondaryMin < mostRecentSecondaryDataPoint) && (!isFinite(thresholdSecondaryMax) || thresholdSecondaryMax > mostRecentSecondaryDataPoint)) {
			// no warn
			secondaryLineColor = theme.palette.dataPlot.line
		} else {
			secondaryLineColor = theme.palette.dataPlot.lineWarn
		}
	}

	let graphProps = {
		endDate,
		selectedTimeScale,
		onChangeTimeScale: (newVal: TimeScale) => {
			if (newVal !== selectedTimeScale) {
				onSetSelectedTimeScale(newVal)
			}
		},
		dataPointType,
		lineColor,
		thresholds: graphData?.thresholds || [],
		dataPoints: graphData?.dataPoints || [],
		markers: alerts.flatMap(a => a.markers),
		secondaryLineColor,
		secondaryDataPointType: secondaryDataPoint,
		secondaryDataPoints: secondaryGraphData?.dataPoints,
		secondaryThresholds: secondaryGraphData?.thresholds,
		graphStyle,
		timeBounds: timeRange
	}

	return (<>
		{error && <ErrorBox message="Failed to load data" />}
		{!loading && <Box width="3rem"><CircularProgress size="24" /></Box>}
		{graphData && <DatatGraph {...graphProps} />}
	</>)

}

type TimeScale = "" | "hour" | "day" | "week" | "all"

export type SingleDataPointGraphModalProps = {
	open: boolean;
	endDate: DateTime;
	dataPointType: DataPointType;
	secondaryDataPoint?: DataPointType;
	patient: IPatientInfo;
	intervalId: ID
	onClose: () => void;
	graphStyle: GraphStyle
}

const SingleDataPointGraphModal: FC<SingleDataPointGraphModalProps> = ({ graphStyle, patient, endDate, intervalId, onClose, open, dataPointType, secondaryDataPoint }) => {
	const classes = useStyles()
	const [selectedTab, setSelectedTab] = useState(0)
	const [supportTablehMode, setSupportTableMode] = useState(false)
	const now = Date.now()
	const [selectedDate, setSelectedDate] = React.useState<Date>(new Date())
	const patientInterval = patient.intervals.find(i => i.id === intervalId)
	const timeRange = {
		start: patientInterval?.intervalStart || (now - 30 * 86400000),
		end: Math.min(now, patientInterval?.intervalEnd || now),
	}
	const [selectedTimeScale, setSelectedTimeScale] = useState<TimeScale>(getDefautTimeScale(timeRange.start))

	useEffect(() => {
		if (!open) {
			return
		}
		// dynamically flip to landscape print when in modal view
		const style = document.createElement('style')
		document.head.appendChild(style)
		style.innerHTML = `@page {size: landscape;}`
		document.body.className += ' printModal'
		return () => {
			style.innerHTML = `@page {size: portrait;}`
			document.body.className = document.body.className.replace(/\sprintModal/ig, "")
		}
	}, [open])

//	let yearAgo = new Date(now - YEAR_MS)
//	yearAgo.setTime(yearAgo.getTime() - yearAgo.getTime() % DAY_MS)

	useEffect(() => {
		setSelectedTimeScale(getDefautTimeScale(timeRange.start))
	}, [dataPointType, timeRange.start])

	useEffect(() => {
		const supports = dataPointType === DataPointType.Weight || dataPointType === DataPointType.BloodPressure || dataPointType === DataPointType.Systolic || dataPointType === DataPointType.Diastolic
		setSupportTableMode(supports)
		if (!supports) {
			setSelectedTab(0)
		}
	}, [dataPointType])

	const graphProps: GraphViewProps = {
		endDate,
		selectedTimeScale,
		onSetSelectedTimeScale: setSelectedTimeScale,
		dataPointType,
		secondaryDataPoint,
		graphStyle,
		intervalId,
		patient,
	}

	const dtProps: DataTableViewProps = {
		dataPointType,
		patient,
		since: selectedDate.getTime(),
		secondaryDataPointType: secondaryDataPoint,
	}

	const onChangeTimeScale = (evt: ChangeEvent<{ value: unknown }>) => {
		setSelectedTimeScale(evt.target.value as TimeScale)
	}

	const onChangeSelectedTab = (_event: ChangeEvent<{}>, newValue: number) => {
		setSelectedTab(newValue)
	}

	const handleDateChange = (date: Date | null) => {
		if (date !== null) {
			setSelectedDate(date)
		}
	}

	return (
		<Dialog classes={{ paper: classes.dialogPaper }} fullWidth maxWidth="xl" onClose={onClose} open={open}>
			<DialogTitle>
				<Box display="flex">
					<Box>
						{patient.firstName} {patient.lastName} - <DataPointLabel dataPoint={dataPointType} /> {!!secondaryDataPoint && <> / <DataPointLabel dataPoint={secondaryDataPoint} /></>}
						<><br />DOB: {patient?.dob && format(new Date(patient.dob + " 00:00:00"), "MM/dd/yyyy")} </>
						<><br />MRN/ID: {patient?.mrn} </>
					</Box>
					<Box flex={1} />

					{supportTablehMode && <Box>

						<Tabs value={selectedTab} indicatorColor="primary" textColor="primary" onChange={onChangeSelectedTab} >
							<Tab label="Graph" />
							<Tab label="30 Day Table" />
						</Tabs>

					</Box>}
					<Box flex={1} />
					{selectedTab === 0 &&
						<Box minWidth="20rem">
							<FormControl fullWidth>
								<InputLabel id="timescale-select-label">Time scale</InputLabel>
								<Select value={selectedTimeScale} onChange={onChangeTimeScale} id="timescale-select" labelId="timescale-select-label">
									<MenuItem value="hour">1 hour</MenuItem>
									<MenuItem value="day">1 day</MenuItem>
									<MenuItem value="week">1 week</MenuItem>
									<MenuItem value="all">All data</MenuItem>
								</Select>
							</FormControl>
						</Box>
					}
					{selectedTab === 1 &&
						<KeyboardDatePicker label="30 Days Since" className="printHidden" variant="inline" format="MM/dd/yyyy" value={selectedDate} onChange={handleDateChange} />
					}
				</Box>
			</DialogTitle>
			<Box flex={1} display="flex" flexDirection="column" style={{ overflowY: "hidden" }} className={selectedTab === 1 ? "print30DayModalContainer" : ""}>
				<Box flex={1} display="flex" flexDirection="column">
					<Box flex={1} display="flex" flexDirection="column" justifyContent="center" alignItems="center" position="relative">
						{selectedTab === 0 && <GraphView {...graphProps} />}
						{selectedTab === 1 && <DataTableView {...dtProps} />}
					</Box>
				</Box>
			</Box>
			<DialogActions>
				<Button variant="contained" onClick={onClose} color="primary">Close</Button>
			</DialogActions>
		</Dialog>
	);
}

export default SingleDataPointGraphModal

import React, { FC, useState, ChangeEvent, useEffect } from 'react'

import { Box, Typography, makeStyles, Button, IconButton, TextField, TextFieldProps, CircularProgress, useTheme, InputProps, Select, SelectProps, MenuItem } from '@material-ui/core'
import { differenceInYears } from 'date-fns'
import StarRateIcon from '@material-ui/icons/StarRate'
import { KeyboardDatePicker, KeyboardDatePickerProps } from "@material-ui/pickers";
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date'
//import InputMask from 'react-input-mask'

import { format } from 'utils/date'
import { IPatientInfo, useUpdatePatient, Sex, useResendInvite } from 'store'
import { PhoneNumberTextField, SnackbarError, SnackbarMessage } from 'components';



const BorderFlag: FC = () => {
	const theme = useTheme()
	return (
		<svg style={{ position: "absolute", right: "-0.6rem", top: "9rem", zIndex: 1 }} width="38" height="44" viewBox="0 0 38 44" fill="none" xmlns="http://www.w3.org/2000/svg">
			<g clipPath="url(#clip0)">
				<path d="M1073 -22H-367V1002H1073V-22Z" fill={theme.palette.background.default} />
				<path d="M1073 -22H-367V70H1073V-22Z" fill={theme.palette.background.default} />
				<path d="M32.5 -76V0.752098L1 21.973L32 43.075V856" stroke={theme.palette.borderColor} />
			</g>
			<defs>
				<clipPath id="clip0">
					<rect width="38" height="44" fill={theme.palette.background.default} />
				</clipPath>
			</defs>
		</svg>
	)
}



const useSidebarStyles = makeStyles((theme) => ({
	ellipsis: {
		display: "inline-block",
		overflow: "hidden",
		textOverflow: "ellipsis",
		position:"relative",
		maxWidth:"100%"
	},
	borderedContainer: {
		flex: 1,
		padding: "2rem 1rem",
		display: "flex",
		flexDirection: "column",
		borderRight: `solid 0.1rem ${theme.palette.borderColor}`,
	},
	table: {
		width: "100%",
		tableLayout: "fixed",
		"& td": {
			"&:first-child": {
				minWidth: "9.5rem"
			},
			verticalAlign: "top",
			padding: "0.3rem 0",
			fontSize: "1.8rem",
			//color: "#000", // why is this one off? all text should be #222
		}
	},
	formField: {
		margin: "-0.3rem 0",
	},
	datePicker: {
		marginTop: "-0.5rem",
		marginBottom: "-0.5rem",
		"& .MuiInputBase-inputAdornedEnd": {
			marginRight: "-1rem",
		}
	},
	favButton: {
	},
	patientNote: {
		whiteSpace: "pre-wrap",
	}
}))

const PatientInfoSidebar: FC<{ patient?: IPatientInfo, isCustomInterval: boolean, hasBodyTrace: boolean, hasSmartMeter: boolean, hasPatientLogin: boolean }> = ({ patient, isCustomInterval, hasBodyTrace, hasSmartMeter, hasPatientLogin }) => {
	const styles = useSidebarStyles()
	const [formState, setFormState] = useState<Partial<IPatientInfo>>({})
	const [inEditMode, setInEditMode] = useState<boolean>(false)
	const [updatePatientFn, saveStatus] = useUpdatePatient()
	const [resendInvite, resendInviteStatus] = useResendInvite()
	const { called, loading, error } = saveStatus

	useEffect(() => {
		setFormState(patient || {})
	}, [inEditMode, patient])

	useEffect(() => {
		if (loading) {
			return
		}
		if (called && !error) {
			setInEditMode(false)
		}

	}, [called, loading, error])


	const onToggleWatched = () => {
		updatePatientFn(patient?.id || "", { watched: !patient?.watched })
	}

	const onUpdateStr = (key: keyof IPatientInfo) => {
		return (evt: ChangeEvent<{ value: string }>) => {
			setFormState({
				...formState,
				[key]: evt.target.value || ""
			})
		}
	}

	const maxLenTf = (len: number) => {
		return { inputProps: { maxLength: len } }
	}

	let txtFieldProps: TextFieldProps = {
		className: styles.formField,
		margin: "dense",
		fullWidth: true,
		...maxLenTf(100),
	}

	let inputProps: InputProps = {
		className: styles.formField,
		margin: "dense",
		fullWidth: true,
	}

	const handleUpdateDob = (date?: MaterialUiPickersDate) => {
		//noop
		setFormState({
			...formState,
			dob: date?.toDateString(),
		})
	}

	let dopDatePickerProps: KeyboardDatePickerProps = {
		value: formState.dob ? new Date(formState.dob + " 00:00:00") : null,
		placeholder: "DOB",
		maxDate: new Date(Date.now()),
		onChange: handleUpdateDob,
		format: "MM/dd/yyyy",
		variant: "inline",
		autoOk: true,
	}

	const sexProps: SelectProps = {
		margin: "dense",
		fullWidth: true,
		style: { margin: "-0.5rem 0" },
		value: formState.sex || "",
		onChange: (evt: ChangeEvent<{ value: unknown }>): void => {
			setFormState({
				...formState,
				sex: evt.target.value ? evt.target.value as Sex : Sex.NotSpecified,
			})
		}
	}
	
	const onResendInvite = () => {
		if (!patient) {
			return
		}
		resendInvite(patient.id)
	}

	const sexOptions = [
		<MenuItem key="ns" value={""}>Not Specified</MenuItem>,
		<MenuItem key="male" value={Sex.Male}>Male</MenuItem>,
		<MenuItem key="female" value={Sex.Female}>Female</MenuItem>,
	]

	const onSave = () => {
		if (!patient) {
			return
		}
		updatePatientFn(patient.id, formState)
	}
	
	if (patient && patient?.dob){
		let dob = new Date(patient?.dob)

		if (isNaN(dob.getTime())) {
			patient = {
				...patient,
				dob: undefined,
			}
		}
	}
	
	let resendSuccess = !resendInviteStatus.error && !resendInviteStatus.loading && resendInviteStatus.called
	let activeInterval = patient?.intervals.find(i =>i.id === patient?.activeInterval?.id)
	return (
		<Box width="100%" minHeight="100%" display="flex" flexDirection="column">
			<SnackbarError error={error} message="Failed to update patient" />
			<SnackbarError error={resendInviteStatus.error} message="Failed to resend invite" />
			<SnackbarMessage severity="success" open={resendSuccess} message="Invite resent" />
			
			<BorderFlag />
			<Box className={styles.borderedContainer}>
				<Box height="12rem" display="flex">
					<Typography variant="h2" style={{maxWidth:"30rem"}}><span className={styles.ellipsis}>{patient?.firstName}</span> <span className={styles.ellipsis}>{patient?.lastName}</span></Typography>
					<Box>
						<IconButton color={patient?.watched ? "secondary" : "inherit"} disabled={loading} onClick={onToggleWatched} className={styles.favButton}><StarRateIcon fontSize="large" /></IconButton>
					</Box>
				</Box>
				<Box flex={1}>
					<table className={styles.table}>
						<tbody>
 							<tr>
								<td>First Name:</td>
								<td>
									{!inEditMode && patient?.firstName}
									{inEditMode && <TextField {...txtFieldProps} value={formState.firstName || ""} onChange={onUpdateStr("firstName")} />}
								</td>
							</tr>
 							<tr>
								<td>Last Name:</td>
								<td>
									{!inEditMode && patient?.lastName}
									{inEditMode && <TextField {...txtFieldProps} value={formState.lastName || ""} onChange={onUpdateStr("lastName")} />}
								</td>
							</tr>
 							<tr>
								<td>MRN/ID:</td>
								<td>
									{!inEditMode && patient?.mrn}
									{inEditMode && <TextField {...txtFieldProps} value={formState.mrn || ""} onChange={onUpdateStr("mrn")} />}
								</td>
							</tr>
							<tr>	
							<td>Age:</td>
								<td>
									{!inEditMode && patient?.dob && differenceInYears(Date.now(), new Date(patient.dob + " 00:00:00"))}
									{inEditMode && !!formState.dob && differenceInYears(Date.now(), new Date(formState.dob + " 00:00:00"))}
								</td>
							</tr>
							<tr>
								<td>DOB:</td>
								<td>
									{!inEditMode && patient?.dob && format(new Date(patient.dob + " 00:00:00"), "MM/dd/yyyy")}
									{inEditMode && <KeyboardDatePicker className={styles.datePicker} {...dopDatePickerProps} />}
								</td>
							</tr>
							<tr>
								<td>Sex:</td>
								<td>
									{!inEditMode && patient?.sex}
									{inEditMode && <Select {...sexProps}>{sexOptions}</Select>}
								</td>
							</tr>
							{!isCustomInterval && <tr>
								<td>Date of Enrollment:</td>
								<td>
									{patient?.invitedOn && format(new Date(patient.invitedOn), "MM/dd/yyyy")}
								</td>
							</tr>}
							{isCustomInterval && <tr>
								<td>End of Interval:</td>
								<td>
									{activeInterval?.intervalEnd  && format(new Date(activeInterval?.intervalEnd), "MM/dd/yyyy")}
								</td>
							</tr>}
							<tr>
								<td>Indication:</td>
								<td>
									{!inEditMode && patient?.indication}
									{inEditMode && <TextField {...txtFieldProps} inputProps={{maxLength:25}} value={formState.indication || ""} onChange={onUpdateStr("indication")} />}
								</td>
							</tr>
							<tr>
								<td>Phone:</td>
								<td>
									{!inEditMode && patient?.phone}
									{inEditMode && <PhoneNumberTextField {...inputProps} value={formState.phone || ""} onChange={onUpdateStr("phone")} />}
								</td>
							</tr>
							{hasBodyTrace && <tr>
								<td>BT Scale IMEI:</td>
								<td>
									{!inEditMode && patient?.bodyTraceScaleImei}
									{inEditMode && <TextField {...txtFieldProps} value={formState.bodyTraceScaleImei || ""} onChange={onUpdateStr("bodyTraceScaleImei")} />}
								</td>
							</tr>}
							{hasBodyTrace && <tr>
								<td>BT BP IMEI:</td>
								<td>
									{!inEditMode && patient?.bodyTraceBpImei}
									{inEditMode && <TextField {...txtFieldProps} value={formState.bodyTraceBpImei || ""} onChange={onUpdateStr("bodyTraceBpImei")} />}
								</td>
							</tr>}
							{hasSmartMeter && <tr>
								<td>SM Scale IMEI:</td>
								<td>
									{!inEditMode && patient?.smartMeterScaleImei}
									{inEditMode && <TextField {...txtFieldProps} value={formState.smartMeterScaleImei || ""} onChange={onUpdateStr("smartMeterScaleImei")} />}

								</td>
							</tr>}
							{hasSmartMeter && <tr>
								<td>SM BP IMEI:</td>
								<td>
									{!inEditMode && patient?.smartMeterBpImei}
									{inEditMode && <TextField {...txtFieldProps} value={formState.smartMeterBpImei || ""} onChange={onUpdateStr("smartMeterBpImei")} />}

								</td>
							</tr>}
							<tr>
								<td>Email:</td>
							</tr>
							<tr>
								<td>
									{patient?.email}
								</td>
							</tr>

							<tr>
								<td>Physician:</td>
								<td>
									{!inEditMode && patient?.physician}
									{inEditMode && <TextField {...txtFieldProps} value={formState.physician || ""} onChange={onUpdateStr("physician")} />}
								</td>
							</tr>
							<tr>
								<td colSpan={2}>Patient Note:</td>
							</tr>
							<tr>
								<td colSpan={2} className={styles.patientNote}>
									{!inEditMode && patient?.patientNote}
									{inEditMode && <TextField fullWidth variant="outlined" rows={8} {...maxLenTf(250)} multiline value={formState.patientNote || ""} onChange={onUpdateStr("patientNote")} />}
								</td>
							</tr>
						</tbody>
					</table>
				</Box>
				<Box display="flex" mt={1}>
					{hasPatientLogin && <Button disabled={!patient || resendInviteStatus.loading} onClick={onResendInvite} variant="contained" color="secondary">Resend invite</Button>}
					<Box flex={1} />
					{loading && <Box mx={1}><CircularProgress size={14} /></Box>}
					{!inEditMode && <Button disabled={!patient || loading} onClick={() => setInEditMode(true)} variant="contained" color="primary">Edit</Button>}
					{inEditMode && <Button disabled={!patient || loading} onClick={onSave} variant="contained" color="secondary">Save</Button>}
				</Box>
			</Box>
		</Box>
	)
}

export default PatientInfoSidebar

import { ApolloError, useQuery } from '@apollo/client'
import gql from 'graphql-tag'


const PRACTICE_QUERY = gql`
query practice {
	practice {
		id
		name
		customInterval
		hasBodyTrace
		hasSmartMeter
		hasPatientLogin
	}
}`


export type IPractice = {
	name: string
	customInterval?: number
	hasBodyTrace: boolean
	hasSmartMeter: boolean
	hasPatientLogin: boolean
}

export const useGetPracticeInfo = (): { loading: boolean; error?: ApolloError; practice?: IPractice; } => {
	const gqlQuery = PRACTICE_QUERY

	const q = useQuery<{ practice: IPractice }>(gqlQuery)
	return {
		...q,
		practice: q.data?.practice,
	}
}
